<template>
  <CurrentEventDataProvider v-slot="{ locale }">
    <BookSessionsDataProvider
      v-slot="{ bookSessions, addOrDeleteBooksSesions, isBookSessionLoading }"
    >
      <CategoriesDataProvider v-slot="{ agendaSessionCategories }">
        <div class="absolute top-0 bottom-0 w-full">
          <AgendasDataProvider
            v-slot="{
              singleAgendaGroupedByDay,
              tracks,
              trackById,
              isLoading,
              setStreamFromSession
            }"
          >
            <DocumentsDataProvider
              v-slot="{ designStyles }"
              :applicablePlatforms="applicablePlatform"
            >
              <div
                v-if="!isLoading"
                class="flex flex-grow h-full"
                :style="designStyles"
              >
                <div class="flex flex-col w-full h-full">
                  <!--header-->
                  <div class="flex justify-between items-center">
                    <div class="p-2 text-md font-bold">
                      <p v-if="bookSessionsEnabled">
                        {{ $t('Views.Stage.Agenda.myAgenda') }}
                      </p>
                      <p v-else>
                        {{ agendaCaption }}
                      </p>
                    </div>
                    <TappinButton
                      v-if="isBookingsEnabled"
                      icon="calendar-alt"
                      class="button border space-x-2"
                      @click="loadMyAgenda"
                      :custom_caption="
                        bookSessionsEnabled
                          ? agendaCaption
                          : $t('Views.Stage.Agenda.myAgenda')
                      "
                    />
                  </div>
                  <!-- Agenda Day -->
                  <div
                    class="flex-grow h-auto overflow-y-auto overflow-x-hidden w-full p-2"
                  >
                    <BookSessions
                      v-if="bookSessionsEnabled"
                      :isAgendaStage="true"
                    />
                    <div v-else>
                      <div class="flex justify-between items-center">
                        <Filters
                          showSearchFilter
                          showCategories
                          @search-filter="handleSearchFilter"
                          @category-filter="handleCategoryFilter"
                          :categories="agendaSessionCategories"
                        />
                      </div>
                      <!-- Success message -->
                      <div v-if="successMessage" class="flex m-2">
                        <div class="border-2 border-green"></div>
                        <div class="success-message p-2 w-full">
                          {{ successMessage }}
                        </div>
                      </div>
                      <CollapsibleList
                        :key="collapsibleListKey"
                        :initialActiveItem="activeDay"
                      >
                        <CollapsibleItem
                          v-for="(day, date) in displayedItems.sessions"
                          v-slot="{ active, toggle }"
                          :key="date"
                          :item-id="date"
                          :agenda="singleAgendaGroupedByDay"
                        >
                          <div class="py-4 border-b border-grey">
                            <!-- Agenda day header -->

                            <div>
                              <button
                                class="button w-full flex justify-between items-baseline"
                                aria-labelledby="agenda date"
                                :aria-expanded="[active ? 'true' : 'false']"
                                aria-controls="expanded-panel"
                                @click="toggle"
                              >
                                <h2 class="text-base xl:text-md">
                                  <Moment
                                    v-if="locale === 'en'"
                                    :value="date"
                                    format="dddd DD MMMM"
                                  />
                                  <Moment
                                    v-if="locale === 'nb'"
                                    :value="date"
                                    format="dddd DD. MMMM"
                                  />
                                </h2>
                                <div aria-hidden="true">
                                  <i v-if="active" class="fas fa-chevron-up" />
                                  <i v-else class="fas fa-chevron-down" />
                                </div>
                              </button>
                            </div>

                            <!-- Agenda Sessions -->
                            <div v-show="active" id="expanded-panel">
                              <CollapsibleList
                                :initialActiveItem="activeSession"
                              >
                                <CollapsibleItem
                                  v-for="session in day"
                                  v-slot="{ active, toggle }"
                                  :key="session.id"
                                  :item-id="session.id"
                                  :agenda="singleAgendaGroupedByDay"
                                  :items="day"
                                >
                                  <!-- TODO: Add slide transition here -->
                                  <div :id="session.id" class="my-2">
                                    <!-- Session content -->
                                    <Card
                                      :containerClass="'flex px-2 py-4 rounded-2xl bg-white space-x-2 w-full '"
                                    >
                                      <div
                                        class="border-4 border-solid"
                                        v-if="
                                          tracks &&
                                          tracks.length > 1 &&
                                          session.trackId != '0' &&
                                          tracks[0].id != session.trackId
                                        "
                                        :style="{
                                          'border-color': trackById(
                                            session.trackId
                                          ).headerBackgroundColor
                                        }"
                                      ></div>
                                      <!-- Session content -->
                                      <div class="space-y-2 py-1 w-full">
                                        <div
                                          class="flex justify-between space-x-2 w-full text-xs items-center"
                                        >
                                          <div
                                            class="flex justify-between space-x-2 items-center"
                                          >
                                            <!--session time-->
                                            <div>
                                              <Moment
                                                :value="session.startDate"
                                                format="HH:mm"
                                              />
                                              -
                                              <Moment
                                                :value="session.endDate"
                                                format="HH:mm"
                                              />
                                            </div>
                                            <!--session track-->
                                            <div
                                              class="inline-block font-bold px-2 text-white"
                                              v-if="
                                                tracks &&
                                                tracks.length > 1 &&
                                                session.trackId != '0'
                                              "
                                              :style="{
                                                'background-color': trackById(
                                                  session.trackId
                                                ).headerBackgroundColor
                                              }"
                                            >
                                              {{
                                                trackById(session.trackId).title
                                              }}
                                            </div>
                                          </div>
                                          <div
                                            class="text-red"
                                            v-if="session.myAgendaSize > 0"
                                          >
                                            {{ totalSeatsAvailable(session) }}
                                            {{
                                              session.myAgendaSize > 1
                                                ? $t(
                                                    'Views.Stage.Agenda.seatsAvailable'
                                                  )
                                                : $t(
                                                    'Views.Stage.Agenda.seatAvailable'
                                                  )
                                            }}
                                          </div>
                                          <!--session booking  loading-->
                                          <div v-if="isBookSessionLoading">
                                            <i
                                              class="fas fa-circle-notch fa-spin"
                                            />
                                          </div>
                                          <!--session booking-->
                                          <div
                                            v-if="
                                              isBookingsEnabled &&
                                              !isBookSessionLoading
                                            "
                                          >
                                            <BookSessionButton
                                              v-if="
                                                !isLimitReached(session) &&
                                                !isSessionPassed(session)
                                              "
                                              @click="
                                                handleSessionClick(
                                                  session,
                                                  bookSessions,
                                                  addOrDeleteBooksSesions
                                                )
                                              "
                                              borderColor="red"
                                              class="button space-x-2"
                                              :class="[
                                                bookSessions.some(
                                                  (ses) => ses.id === session.id
                                                )
                                                  ? 'text-red border border-red	'
                                                  : 'border'
                                              ]"
                                              :icon="[
                                                bookSessions.some(
                                                  (ses) => ses.id === session.id
                                                )
                                                  ? 'minus-square'
                                                  : 'calendar-plus-o'
                                              ]"
                                              :custom_caption="
                                                bookSessions.some(
                                                  (ses) => ses.id === session.id
                                                )
                                                  ? $t(
                                                      'Views.Stage.Agenda.cancel'
                                                    )
                                                  : $t(
                                                      'Views.Stage.Agenda.attend'
                                                    )
                                              "
                                            />

                                            <button
                                              class="text-base w-10 fa"
                                              v-else
                                            >
                                              <i class="fa fa-sm fa-lock"></i>
                                            </button>
                                          </div>
                                        </div>
                                        <!-- Session title and sub-title -->
                                        <div>
                                          <h3 class="font-bold text-base">
                                            {{ session.title }}
                                          </h3>
                                          <h4>
                                            {{ session.subtitle }}
                                          </h4>
                                        </div>
                                        <!-- Session Actions -->
                                        <div
                                          v-if="
                                            session.description ||
                                            session.speakers.length ||
                                            (session.docIds &&
                                              session.docIds.length > 0) ||
                                            session.liveUrl
                                          "
                                        >
                                          <div
                                            class="flex justify-start text-sm space-x-2"
                                          >
                                            <!-- Agenda more info -->
                                            <div
                                              v-if="
                                                session.description ||
                                                session.speakers.length
                                              "
                                              class="button button-secondary"
                                              aria-label="Learn more about session"
                                              :aria-expanded="[
                                                active ? 'true' : 'false'
                                              ]"
                                              aria-controls="expanded-panel"
                                              @click="toggle"
                                            >
                                              {{
                                                active
                                                  ? $t(
                                                      'Views.Stage.Agenda.showLess'
                                                    )
                                                  : $t(
                                                      'Views.Stage.Agenda.moreInfo'
                                                    )
                                              }}
                                            </div>
                                            <!-- Agenda scene -->
                                            <div
                                              v-if="session.liveUrl"
                                              id="stageStream"
                                              class="button button-primary"
                                              :aria-label="
                                                $t(
                                                  'Views.Stage.Agenda.goToScene'
                                                )
                                              "
                                              @click="
                                                setStreamFromSession(session)
                                              "
                                            >
                                              {{
                                                $t(
                                                  'Views.Stage.Agenda.goToScene'
                                                )
                                              }}
                                            </div>
                                            <!-- Agenda documents by session -->
                                            <AgendaDocuments
                                              v-bind:session="session"
                                            ></AgendaDocuments>
                                          </div>
                                          <!-- TODO: Add slide down transition here -->
                                          <!-- Expanded Session info -->
                                          <div
                                            v-if="active"
                                            id="expanded-panel"
                                          >
                                            <!-- Session Description -->
                                            <div
                                              ref="childElement"
                                              v-if="session.speakers.length"
                                              class="flex flex-row flex-wrap p-2"
                                            >
                                              <!-- Session Speaker -->
                                              <div
                                                v-for="speaker in session.speakers"
                                                :key="speaker.id"
                                                class="flex items-center space-x-2 space-y-2 w-1/2"
                                              >
                                                <Avatar
                                                  :circle="true"
                                                  :size="'small'"
                                                />
                                                <div
                                                  class="flex flex-col text-xs"
                                                >
                                                  <span class="font-bold">{{
                                                    speaker.name +
                                                    ' ' +
                                                    speaker.lastName
                                                  }}</span>
                                                  <span v-if="speaker.jobTitle">
                                                    {{ speaker.jobTitle }}</span
                                                  >
                                                  <span v-if="speaker.company">
                                                    {{ speaker.company }}</span
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="text-sm html-content"
                                              v-html="session.description"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </CollapsibleItem>
                              </CollapsibleList>
                            </div>
                          </div>
                        </CollapsibleItem>
                      </CollapsibleList>
                    </div>
                  </div>
                </div>
              </div>
            </DocumentsDataProvider>
          </AgendasDataProvider>
        </div>
      </CategoriesDataProvider>
    </BookSessionsDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import CollapsibleList from '@/components/shared/CollapsibleList';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import Card from '@/components/shared/webapp/Card';
import TappinButton from '@/components/buttons/TappinButton.vue';
import BookSessions from '@/components/profile/BookSessions.vue';
import Avatar from '@/components/shared/Avatar';
import BookSessionButton from '@/components/buttons/BookSessionButton.vue';
import Filters from '@/components/shared/Filters.vue';
import AgendaDocuments from '@/views/Stage/Agenda/AgendaDocuments.vue';
import AgendaConfirmationModal from '@/views/Stage/Agenda/AgendaConfirmationModal';
import AgendaDeleteConfirmationModal from '@/views/Stage/Agenda/AgendaDeleteConfirmationModal';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getPusherInstance } from '@/utils';
import ApiClient from '@/client';

export default {
  name: 'Agenda',
  components: {
    CollapsibleList,
    CollapsibleItem,
    Card,
    BookSessions,
    TappinButton,
    BookSessionButton,
    Avatar,
    Filters,
    AgendaDocuments
  },
  created() {
    getPusherInstance({ isReady: true }).bind(`agenda-change`, () => {
      this.getDocuments();
      this.fetchItems();
    });
  },
  mounted() {
    this.$root.$on('set-active', this.checkActive);
    this.$root.$on('set-active-myAgenda', this.loadMyAgenda);
    this.$root.$on('focusHere', this.handleTabChange);
    this.scrollToElement();
  },
  beforeDestroy() {
    this.activeDay = null;
    this.activeSession = null;
  },
  data() {
    return {
      bookSessionsEnabled: false,
      searchFilter: '',
      categoryFilter: '',
      activeSession: '',
      activeDay: '',
      collapsibleListKey: 0,
      successMessage: '',
      successTimeout: null
    };
  },
  watch: {
    activeDay(newActiveDay) {
      this.collapsibleListKey = newActiveDay;
      this.$nextTick(() => {
        this.scrollToElement(this.activeSession);
      });
    }
  },
  methods: {
    ...mapActions('Agendas', ['filterSessions']),
    ...mapMutations('Agendas', ['setAgenda']),
    ...mapActions('Documents', ['getDocuments']),
    checkActive(e) {
      this.bookSessionsEnabled = false;
      this.activeDay = e.date;
      this.activeSession = e.data;
    },
    scrollToElement(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    handleTabChange() {
      this.activeDay = '';
      this.activeSession = '';
    },
    loadMyAgenda() {
      return (this.bookSessionsEnabled = !this.bookSessionsEnabled);
    },
    handleSearchFilter(e) {
      this.searchFilter = e;
    },
    handleCategoryFilter(e) {
      this.categoryFilter = e;
    },
    filterAgendaSessions() {
      if (this.searchFilter || this.categoryFilter) {
        this.filterSessions({
          searchText: this.searchFilter,
          category: this.categoryFilter
        });
        return this.$store.getters['Agendas/filteredSingleAgendaGroupedByDay'];
      }
      return this.$store.getters['Agendas/singleAgendaGroupedByDay'];
    },
    fetchItems() {
      ApiClient.getAgendas().then((r) => {
        this.setAgenda(r.data);
      });
    },
    handleSessionClick(session, bookSessions, addOrDeleteBooksSesions) {
      // Check if myAgendaSize has a value and show the details if it does
      if (session.myAgendaSize) {
        bookSessions.some((ses) => ses.id === session.id)
          ? this.showDeleteConfirmation(session)
          : this.showConfirmation(session);
        return;
      }

      addOrDeleteBooksSesions(session);

      const isSessionOnMyAgenda = bookSessions.some(
        (ses) => ses.id === session.id
      );
      this.successMessage = isSessionOnMyAgenda
        ? 'Successfully removed agenda'
        : 'Successfully added agenda';

      if (this.successTimeout) {
        clearTimeout(this.successTimeout);
      }

      this.successTimeout = setTimeout(() => {
        this.successMessage = '';
      }, 1500);
    },
    showConfirmation(session) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        AgendaConfirmationModal,
        { session },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 550,
          width: '45%',
          height: 'auto'
        }
      );
    },
    showDeleteConfirmation(session) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        AgendaDeleteConfirmationModal,
        { session },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 550,
          width: '45%',
          height: 'auto'
        }
      );
    }
  },
  computed: {
    applicablePlatform() {
      return this.$route.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
    },
    ...mapGetters('Authentication', ['getUser']),
    ...mapGetters('CurrentEvent', ['getEvent']),
    isSessionPassed() {
      return (session) => {
        return this.$moment(session.endDate).isBefore(this.$moment());
      };
    },
    isLimitReached() {
      return (session) => {
        const checkUser = session.userIds.some(
          (userId) => userId === this.getUser.UserID
        );
        return (
          !checkUser &&
          session.userIds.length === session.myAgendaSize &&
          session.myAgendaSize !== 0
        );
      };
    },
    isBookingsEnabled() {
      const event = this.getEvent;
      return event.features.bookingsEnabled;
    },
    displayedItems() {
      return this.filterAgendaSessions();
    },
    agendaCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.programCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'agenda'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    totalSeatsAvailable() {
      return (session) => {
        return session.myAgendaSize - session.userIds.length;
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.description-container {
  & iframe {
    max-width: 399px;
    max-height: 224px;
  }
}
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
.bg-past-session {
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.109); /* semi-transparent overlay */
  z-index: 1; /* ensures the overlay is above the content */
  pointer-events: none; /* makes the overlay non-interactive */
}
.success-message {
  background-color: #c1e1c1;
  color: green;
  pointer-events: none;
}
</style>
