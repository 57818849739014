import axios from 'axios';
import store from './store';
import fileDownload from 'js-file-download';

class ApiClient {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL
    });

    this.service.interceptors.request.use(
      function (config) {
        const eventId = store.getters['CurrentEvent/getId'];
        const user = JSON.parse(localStorage.getItem(`tokens_${eventId}`));
        if (user) {
          config.headers['Authorization'] = `Bearer ${user.access_token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    this.service.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          // handle error: inform user, go to login, etc
          store.dispatch('Authentication/doLogout');
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  // Internal methods
  //==========================================================================================================
  _getEventId() {
    if (!this.username) {
      this.username = store.getters['CurrentEvent/getId'];
    }
    return this.username;
  }

  // Authentication
  //==========================================================================================================
  login(email, password, eventId, visible = true, pin = null, pinId = null) {
    const data = {
      eventId: this._getEventId(),
      username: email,
      password,
      visible,
      pin,
      pinId,
      grant_type: 'password',
      scope: 'openid offline_access',
      login_hint: 'liveEvent'
    };

    return this._autheticate(eventId, data);
  }

  passwordlessLogin(payload, eventId, visible = true) {
    const data = {
      eventId: eventId,
      visible,
      token: payload,
      grant_type: 'otp',
      scope: 'openid offline_access',
      login_hint: 'liveEvent'
    };

    return this._autheticate(eventId, data);
  }

  async _autheticate(eventId, data) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    const params = new URLSearchParams();
    Object.keys(data).forEach((key) => params.append(key, data[key]));

    return this.service
      .post(`/connect/token`, params, config)
      .then((response) => {
        const tokens = response.data;
        localStorage.setItem(`tokens_${eventId}`, JSON.stringify(tokens));
        return response;
      });
  }

  async requestNewPasswordlessLoginLink(eventId, payload, deliveryMethod) {
    const data = {
      deliveryMethod: deliveryMethod,
      payload: payload
    };
    return this.service.post(
      `/api/Registration/${eventId}/passwordless/resend`,
      data
    );
  }

  async recoverPasswordBySms(countryPrefix, phoneNumber, eventId) {
    const username = this._getEventId();
    return this.service.get(
      `/${username}/api.php?module=password&method=newPasswordViaSms&countryPrefix=${countryPrefix}&phoneNumber=${phoneNumber}&eventId=${eventId}`
    );
  }

  async recoverPasswordByEmail(email, eventId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/resetPassword/${email}`
    );
  }

  getTwoFactorPin(email, eventId) {
    const username = this._getEventId();
    return this.service.get(
      `/${username}/api.php?module=authentication&method=getTwoFactorPin&useremail=${email}&eventid=${eventId}`
    );
  }

  getSignedCookiesForRecordings() {
    return this.service.get(`/api/liveEvent/generateSignedCookies`, {
      withCredentials: true
    });
  }

  // Profile / Currently logged in user
  //==========================================================================================================
  getCurrentInfoUserFromSession() {
    const username = this._getEventId();

    return this.service
      .get(`/api/liveEvent/${username}/getOwnUserInfoFromSession`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .catch((err) => {
        if (err.indexOf('401')) {
          throw new Error('Unauthorized');
        }
        throw err;
      });
  }

  updateProfile(formData) {
    const username = this._getEventId();

    return this.service.post(`/api/liveEvent/${username}/updateUser`, formData);
  }

  toggleProfileVisibility() {
    const username = this._getEventId();

    return this.service.post(`/api/liveEvent/${username}/toggleUserVisibility`);
  }

  async uploadProfileAvatar(file) {
    const username = this._getEventId();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const data = { name: file.name, image: reader.result };

        resolve(
          this.service
            .post(`/api/liveEvent/${username}/uploadUserAvatar`, data)
            .catch(reject)
        );
      };
    });
  }

  // Event
  //==========================================================================================================
  getPublicEventInfo(eventId) {
    return this.service.get(`/api/liveEvent/publicEventInfo/${eventId}`);
  }

  getFullEventInfoForEventCurrentlyLoggedInTo() {
    return this.service.get(`/api/liveEvent/getEventInfo`);
  }

  getFullEventInfo(eventSlug, pollAccessKey) {
    return this.service.get(
      `/api/liveEvent/${eventSlug}/eventInfo/${pollAccessKey}`
    );
  }

  getPublicFullEventInfo(eventSlug) {
    return this.service.get(`/api/liveEvent/${eventSlug}/publicFullEventInfo`);
  }

  // Chat
  //==========================================================================================================
  getChats() {
    const eventId = this._getEventId();
    return this.service.get(`/api/liveEvent/${eventId}/chat`);
  }

  sendMessage(payload) {
    const eventId = this._getEventId();
    return this.service.post(
      `/api/liveEvent/${eventId}/chat/${payload.chatid}`,
      {
        message: payload.message
      }
    );
  }

  createChat(payload) {
    const eventId = this._getEventId();
    return this.service.post(`/api/liveEvent/${eventId}/chat`, {
      userId: payload
    });
  }

  MarkChatAsRead(payload) {
    const eventId = this._getEventId();
    return this.service.put(
      `/api/liveEvent/${eventId}/chat/${payload.chatid}/read`
    );
  }

  // Participants (users registered to event)
  //==========================================================================================================
  getUsers(page = 1, pageSize = 50, search = '', groupId = '') {
    const username = this._getEventId();
    const query = new URLSearchParams({
      page,
      size: pageSize,
      search,
      groupId
    });
    return this.service.get(`/api/liveEvent/${username}/users?${query}`);
  }

  getUserCount() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/users/count`);
  }

  // Public Group
  //==========================================================================================================
  getPublicGroups() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/user/groups`);
  }

  // Speakers
  //==========================================================================================================
  getSpeakers(page = 1, pageSize = 20, search = '') {
    const username = this._getEventId();
    const query = new URLSearchParams({
      page,
      size: pageSize,
      search
    });
    return this.service.get(
      `/api/liveEvent/${username}/liveGetSpeakerInfo?${query}`
    );
  }

  getSpeakerById(speakerId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/liveGetSpeakerInfo/${speakerId}`
    );
  }

  getIframeSpeakers(page = 1, pageSize = 20, search = '') {
    const username = this._getEventId();
    const query = new URLSearchParams({
      page,
      size: pageSize,
      search
    });
    return this.service.get(
      `/api/liveEvent/${username}/liveGetIframeSpeakerInfo?${query}`
    );
  }
  // Sponsors
  //==========================================================================================================
  sendContactMessageToSponsor(sponsorId, message) {
    const username = this._getEventId();

    const params = new URLSearchParams();
    params.append('SponsorId', sponsorId);
    params.append('Message', message);

    return this.service.post(
      `/api/liveEvent/${username}/sendContactMessage`,
      params,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
    );
  }

  getSponsors() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/sponsors`);
  }

  getSponsor(sponsorId) {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/sponsors/${sponsorId}`);
  }

  getSponsorWherebyRooms(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sponsors/${sponsorId}/wherebyRooms`
    );
  }

  getSponsorUsers(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sponsors/${sponsorId}/users`
    );
  }

  getSponsorPolls(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sponsors/${sponsorId}/polls`
    );
  }

  getSponsorQuizzes(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sponsors/${sponsorId}/quizzes`
    );
  }

  getSponsorDocuments(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sponsors/${sponsorId}/documents`
    );
  }

  requestSponsorUserMeeting(userId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/requestMeeting/${userId}`
    );
  }

  sendContactInfoToSponsor(sponsorId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/sendContactInfo/${sponsorId}`
    );
  }

  getFilteredPolls(fromDate, toDate, groups, pollAccessKey) {
    const username = this._getEventId();
    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });
    return this.service.get(
      `/api/liveEvent/${username}/filteredPollResults/${pollAccessKey}?${query}`
    );
  }

  // Documents
  //==========================================================================================================
  getDocuments() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/getAllDocuments`);
  }

  getDocument(documentId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/documents/${documentId}`
    );
  }

  getDocumentsBySessionId(sessionId) {
    const eventId = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${eventId}/sessions/${sessionId}/documents`
    );
  }

  sendAllDocuments() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/sendAllDocuments`);
  }

  // Infopages
  //==========================================================================================================
  getLivePages() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/getInfoPages`);
  }

  getPage(pageId) {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/getPage/${pageId}`);
  }

  // Agenda
  //==========================================================================================================
  getAgendas() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/liveGetAgendaInfo`);
  }

  getIframeAgendas() {
    const username = this._getEventId();

    return this.service.get(
      `/api/liveEvent/${username}/liveGetIframeAgendaInfo`
    );
  }

  // Book Sessions
  //==========================================================================================================

  getBookSessions() {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/liveGetAgendaInfo/bookSession`
    );
  }

  addBookSession(model) {
    const username = this._getEventId();
    return this.service.post(
      `api/liveEvent/${username}/liveGetAgendaInfo/bookSession`,
      {
        EventId: username,
        SessionId: model.id
      }
    );
  }

  deleteBookSession(model) {
    const username = this._getEventId();
    return this.service.delete(
      `api/liveEvent/${username}/liveGetAgendaInfo/${model.id}/bookSession`
    );
  }

  // Groups
  //==========================================================================================================
  getGroups() {
    const username = this._getEventId();
    return this.service.get(`api/liveEvent/${username}/groups`);
  }

  // Terms of service
  //==========================================================================================================
  storeTermsOfServiceAcceptance(email, termsOfServiceText, eventId) {
    return this.service.post(
      `/api/liveEvent/${eventId}/storeTermsAcceptance/${email}`,
      { termsText: termsOfServiceText?.termsText || termsOfServiceText }
    );
  }

  getTermsOfServiceText(email, eventId) {
    return this.service.get(`/api/liveEvent/${eventId}/getTermsText/${email}`);
  }

  // Timeline
  //==========================================================================================================
  getTimelineItems(limit = 50) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/getAllTimelineItems/${limit}`
    );
  }

  getPromotedTimelineItems() {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/getPromotedTimelineItems`
    );
  }

  addTimelineEntry(message, files) {
    const username = this._getEventId();

    var formData = new FormData();
    formData.append('message', message || '');
    for (const file of files) {
      formData.append('files', file);
    }

    return this.service.post(
      `/api/liveEvent/${username}/addTimelineEntry`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  addTimelineComment(entryId, message, files) {
    const username = this._getEventId();

    var formData = new FormData();
    formData.append('inReplyTo', entryId);
    formData.append('message', message || '');
    for (const file of files) {
      formData.append('files', file);
    }

    return this.service.post(
      `/api/liveEvent/${username}/addTimelineComment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  deleteTimelineEntry(entryId) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/deleteTimelineEntry`, {
      timelineId: entryId
    });
  }
  deleteAgendaTimelineEntry(payload) {
    const username = this._getEventId();
    return this.service.post(
      `/${username}/api.php?&module=timeline&method=deleteEntry`,
      null,
      {
        params: { timelineId: payload.TimelineID, sessionId: payload.SessionId }
      }
    );
  }
  likeTimelineEntry(entryId) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/likeTimelineEntry`, {
      timelineId: entryId
    });
  }

  unlikeTimelineEntry(entryId) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/unlikeTimelineEntry`, {
      timelineId: entryId
    });
  }

  // Poll
  //==========================================================================================================
  getPoll(pollId, isCodedPoll) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/liveGetPoll/${pollId}/${isCodedPoll}`
    );
  }

  getPollStream(eventId, accessKey, fromDate, toDate, groups) {
    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });
    return this.service.get(
      `/api/liveEvent/${eventId}/pollStream/${accessKey}?${query}`
    );
  }

  getQuizStream(accessKey, fromDate, toDate, groups) {
    const username = this._getEventId();
    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });
    return this.service.get(
      `/api/liveEvent/${username}/quizStream/${accessKey}?${query}`
    );
  }

  submitFreeTextPollAnswer(pollId, questionType, answer) {
    const username = this._getEventId();
    return this.service.post(
      `/api/liveEvent/${username}/liveStoreFreeTextAnswer`,
      {
        PollID: pollId,
        QuestionType: questionType,
        FreeText: answer
      }
    );
  }

  loginToResults(password, pollAccessKey) {
    const username = this._getEventId();

    return this.service.post(`/api/liveEvent/${username}/connect/poll`, {
      password: password,
      pollAccessKey: pollAccessKey
    });
  }

  getPollResults(accessKey, fromDate, toDate, groups) {
    const username = this._getEventId();
    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });

    return this.service.get(
      `/api/liveEvent/${username}/pollResults/${accessKey}?${query}`
    );
  }

  getTemplatePollResults(accessKey, fromDate, toDate, groups) {
    const username = this._getEventId();

    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });

    return this.service.get(
      `/api/liveEvent/${username}/templatePollResults/${accessKey}?${query}`
    );
  }

  getPollUsingTemplateResults(accessKey, fromDate, toDate, groups) {
    const username = this._getEventId();

    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });

    return this.service.get(
      `/api/liveEvent/${username}/pollTemplateResults/${accessKey}?${query}`
    );
  }

  getGenericPollResults() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/pollResults`);
  }

  submitMultichoicePollAnswer(pollId, questionType, alternatives) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/liveStorePollAnswer`, {
      PollID: pollId,
      QuestionType: questionType,
      Alternatives: alternatives
    });
  }

  submitQuizAnswer(data) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/quizAnswer`, data);
  }

  submitTemplateQuizAnswer(data) {
    const username = this._getEventId();
    return this.service.post(
      `/api/liveEvent/${username}/templateQuizAnswer`,
      data
    );
  }

  submitFreeTextPollAnswerChanges(
    eventSlug,
    accessKey,
    answerId,
    label,
    presentType
  ) {
    return this.service.post(
      `/api/LiveEvent/${eventSlug}/togglePollAnswerPresentation/${accessKey}`,
      {
        Label: label,
        PresentType: presentType,
        AnswerId: answerId
      }
    );
  }

  async exportPollResults(
    accessKey,
    resultsPageType,
    fromDate,
    toDate,
    groups,
    withRegistrations = true
  ) {
    const username = this._getEventId();

    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups,
      withRegistrations
    });

    return this.service
      .get(
        `/api/liveEvent/${username}/pollResults/${accessKey}/export/${resultsPageType}?${query}`,
        { responseType: 'blob' }
      )
      .then(handleExport);
  }

  async exportTemplatePollResults(
    id,
    resultsPageType,
    fromDate,
    toDate,
    groups
  ) {
    const username = this._getEventId();
    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });
    return this.service
      .get(
        `/api/liveEvent/${username}/templateResults/${id}/export/${resultsPageType}?${query}`,
        { responseType: 'blob' }
      )
      .then(handleExport);
  }

  async exportPollUsingTemplateResults(
    id,
    resultsPageType,
    fromDate,
    toDate,
    groups
  ) {
    const username = this._getEventId();

    const query = new URLSearchParams({
      fromDate,
      toDate,
      groups
    });

    return this.service
      .get(
        `/api/liveEvent/${username}/pollTemplateResults/${id}/export/${resultsPageType}?${query}`,
        { responseType: 'blob' }
      )
      .then(handleExport);
  }

  async exportQuizList() {
    const username = this._getEventId();
    return this.service
      .get(`/api/liveEvent/${username}/export/quizList`, {
        responseType: 'blob'
      })
      .then(handleExport);
  }

  // Selfie
  //==========================================================================================================
  async getAllSelfieOverlays() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/getAllSelfieOverlays`);
  }

  async uploadSelfieImage(base64ImageUrl) {
    const username = this._getEventId();

    var data = new FormData();
    data.append('image', base64ImageUrl);
    return this.service.post(
      `/api/liveEvent/${username}/uploadSelfieImage`,
      data
    );
  }

  async uploadBase64ImageAsProfileAvatar(base64ImageUrl) {
    const username = this._getEventId();
    var data = { image: base64ImageUrl };

    return this.service.post(
      `/api/liveEvent/${username}/uploadUserAvatar`,
      data
    );
  }

  // PWA
  async getUserManifest() {
    return this.service.get(`/api/liveEvent/manifest/me`);
  }

  async getManifest() {
    return this.service.get(`/api/liveEvent/manifest`);
  }

  // Voucher balance
  //==========================================================================================================
  getVoucherBalance(userId) {
    const username = this._getEventId();
    return this.service.get(
      `/api/liveEvent/${username}/vouchers/${userId}/transactions`
    );
  }

  // Categories
  //==========================================================================================================

  getCategories() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/categories`);
  }

  getPublicCategories() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/publicCategories`);
  }

  getSponsorCategories() {
    const username = this._getEventId();
    return this.service.get(`/api/liveEvent/${username}/sponsors/categories`);
  }

  // Participants shared list
  //==========================================================================================================
  getParticipantsSharedList(eventSlug, pinCode) {
    return this.service.get(
      `/api/liveEvent/${eventSlug}/participants/${pinCode}`
    );
  }

  exportParticipantsSharedLink(eventSlug, pinCode) {
    return this.service
      .get(
        `/api/liveEvent/${eventSlug}/participantsSharedLink/export/${pinCode}`,
        { responseType: 'blob' }
      )
      .then(handleExport);
  }

  // Participants admitted on
  //==========================================================================================================
  getParticipantsAdmission(groups, fromDate, toDate) {
    const username = this._getEventId();

    return this.service.post(
      `/api/liveEvent/${username}/participantsAdmission`,
      {
        fromDate,
        toDate,
        groups
      }
    );
  }

  loginParticipantsToResults(password, accessKey) {
    const username = this._getEventId();
    return this.service.post(`/api/liveEvent/${username}/connect/participant`, {
      password: password,
      accessKey: accessKey
    });
  }

  exportParticipantsAdmission(
    groups,
    fromDate,
    toDate,
    addAdmittedRows,
    addNoAdmitedRows
  ) {
    const username = this._getEventId();
    return this.service
      .post(
        `/api/liveEvent/${username}/participantsAdmission/export`,
        {
          fromDate,
          toDate,
          groups,
          addAdmittedRows,
          addNoAdmitedRows
        },
        { responseType: 'blob' }
      )
      .then(handleExport);
  }

  // QR Ticket (check registration id)
  //==========================================================================================================
  getRegistrationId(registrationId) {
    const eventId = this._getEventId();

    return this.service.get(
      `/api/liveEvent/${eventId}/participant/${registrationId}`
    );
  }
}

function handleExport(result) {
  const blob = new Blob([result.data], {
    type: result.headers['content-type']
  });

  if (!result.headers['x-filename']) {
    return Promise.reject();
  }

  fileDownload(blob, result.headers['x-filename']);
  return Promise.resolve();
}

export default new ApiClient();
