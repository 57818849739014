<template>
  <CurrentEventDataProvider v-slot="{ data, logoUrl }">
    <div class="flex flex-wrap overflow-hidden w-full h-full md:space-y-2">
      <!-- Navbar -->
      <div
        v-if="showPartial"
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!--  -->
      <div class="flex flex-row w-full h-full px-2 md:px-4">
        <!-- Sidebar -->
        <div
          v-if="showSlider && showPartial"
          class="w-full md:w-1/4 flex flex-col"
        >
          <div class="sidebar-container shadow-lg p-4 space-y-4 md:space-y-6">
            <!-- Event detail -->
            <div class="flex justify-between items-center w-full">
              <div class="shadow-md p-1 rounded-full">
                <img
                  :src="logoUrl"
                  :alt="logoUrl && 'Event\'s logo'"
                  class="object-scale-down h-8 w-8"
                />
              </div>
              <h2 class="font-bold text-md">
                {{ data.eventName }}
              </h2>
              <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
            </div>
            <!-- Filters refresh-->
            <div
              class="flex flex-col md:flex-row justify-between md:items-center w-full"
            >
              <!-- Filters hidden for small screen-->
              <h2
                class="hidden md:flex font-bold text-base pt-2 md:pt-0 items-center"
              >
                Filters
              </h2>
              <div class="flex w-full justify-between md:justify-end">
                <TappinButton
                  :disabled="loading"
                  :icon="loading ? 'circle-notch' : 'repeat'"
                  @click="refreshItems"
                  class="button button-secondary text-center items-center"
                  :aria-label="loading ? 'loading' : 'Refresh'"
                  :custom_caption="
                    loading ? '' : $t('Views.PollResults.refresh')
                  "
                />
                <TappinButton
                  v-if="showSlider"
                  :icon="'close'"
                  @click="toggleSlider"
                  class="inline md:hidden whitespace-nowrap button button-secondary"
                  :aria-label="'Close filter'"
                  :custom_caption="'close filter'"
                />
              </div>
              <!-- Filters hidden for large screen-->
              <h2 class="md:hidden font-bold text-base pt-2 md:pt-0">
                Filters
              </h2>
            </div>
            <!-- Date picker -->
            <div class="flex flex-col gap-2">
              <h2 class="font-bold text-base">Date</h2>
              <Filters
                showDates
                @date-filter="handleDates"
                @checkbox-filter="handleCheckBoxFilters"
              />
            </div>
            <!-- Group -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">
                  {{ $t('Components.Filters.groups') }}
                </h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <Filters
                showCheckbox
                @checkbox-filter="handleCheckBoxFilters"
                :groups="groups"
                :tableCheckBoxName="$t('Components.Filters.groups')"
                showGroupModal
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div
          :class="
            showSlider
              ? showPartial
                ? 'w-1/6 md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
                : 'w-full'
              : 'w-full md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
          "
        >
          <!--loader-->
          <div v-if="loading" slot="footer" class="loading-spinner">
            <Spinner />
          </div>
          <div
            class="poll-results md:shadow-lg p-2 md:p-4 space-y-0 md:space-y-6 overflow-x-hidden"
            style="
              color: var(--login-color-text);
              background-color: var(--login-background-color);
            "
          >
            <!--header and export-->
            <div v-if="showPartial" class="flex justify-end w-full space-x-2">
              <div class="button flex lg:hidden" @click="toggleSlider">
                <TappinButton
                  v-if="!showSlider"
                  icon="filter"
                  class="button button-primary"
                  :custom_caption="'Filters'"
                />
              </div>
            </div>
            <!--poll content-->
            <div
              :class="[
                'flex',
                'flex-col',
                'flex-wrap',
                'h-full',
                'bg-white',
                'text-dark',
                'justify-between',
                'py-2'
              ]"
            >
              <div class="text-center" v-if="!isPollLoaded && !isPollError">
                {{ $t('Views.PollResults.loading') }}
              </div>
              <div
                v-else-if="isPollLoaded && !isPollError"
                class="flex flex-wrap h-full w-full"
              >
                <div
                  class="flex justify-between mb-8 shrink-0 grow-0 flex-auto"
                >
                  <h1 class="font-bold text-md md:text-lg">
                    {{ poll.title }}
                  </h1>
                </div>
                <div class="flex justify-between w-full mb-4">
                  <p class="text-md">Answers: {{ poll.votes.length }}</p>
                </div>
                <Card
                  v-for="item in poll.votes"
                  :key="item.createdOn"
                  class="item p-2 flex justify-between w-full"
                >
                  <div
                    class="text flex flex-col flex-grow-1 shrink-1"
                    :class="{ 'opacity-25': item.isHidden }"
                  >
                    <div class="time text-xs mb-2 font-bold text-dark">
                      <Moment
                        :value="item.createdOn"
                        format="YYYY-MM-DD HH:mm:ss"
                        :is-offset="false"
                      />
                    </div>
                    <div class="time text-xs mb-2 font-bold text-dark">
                      <span
                        >{{ item.createdByName }}
                        {{ item.createdByLastname }}</span
                      >
                    </div>
                    <div class="question">
                      <span v-if="!item.isHidden">{{ item.label }}</span>
                      <s v-else>{{ item.label }}</s>
                    </div>
                  </div>
                  <div
                    class="actions ml-8 flex grow-0 shrink-0 flex-col-reverse items-end sm:flex-row sm:items-start"
                  >
                    <button
                      class="button button-secondary mt-4 sm:mt-0 sm:mr-4"
                      @click="toggleListQuestion(item)"
                    >
                      {{ item.isHidden ? 'List' : 'Unlist' }}
                    </button>
                    <button
                      v-if="!item.isHidden"
                      class="button button-primary"
                      @click="togglePresentQuestion(item)"
                    >
                      {{ item.isSelected ? 'x' : 'Present' }}
                    </button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import { getPusherInstance } from '@/utils';
import Card from '@/components/shared/Card';
import Filters from '@/components/shared/Filters.vue';
import Spinner from '@/components/shared/Spinner';
import TappinButton from '@/components/buttons/TappinButton.vue';

export default {
  components: {
    Card,
    Filters,
    Spinner,
    TappinButton
  },
  props: {
    pollAccessKey: String,
    showPartial: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      isPollLoaded: false,
      isPollError: false,
      poll: {
        votes: []
      },
      eventId: window.location.host.split('.')[0],
      fromDate: '',
      toDate: '',
      groupsFilter: [],
      debouncedSearchTimeout: null,
      filterLoading: false,
      showSlider: false
    };
  },
  created() {
    this.fetchItems();
    this.fetchGroups();

    this.checkScreenSize();
    // Add an event listener to react to screen resize
    window.addEventListener('resize', this.checkScreenSize);

    getPusherInstance({ isReady: true }).bind(
      `poll-change-${this.pollAccessKey}`,
      () => {
        this.fetchItems();
      }
    );
  },
  computed: {
    ...mapGetters('Groups', { groups: 'getItems' })
  },
  methods: {
    ...mapActions('Groups', { fetchGroups: 'fetchItems' }),
    toggleSlider() {
      this.showSlider = !this.showSlider;
    },
    fetchItems() {
      ApiClient.getPollStream(
        this.eventId,
        this.pollAccessKey,
        this.fromDate,
        this.toDate,
        this.groupsFilter
      )
        .then((response) => {
          this.poll = response.data;
          this.isPollLoaded = true;
          this.isPollError = false;
          this.poll.votes.sort(function (a, b) {
            if (a.isHidden !== b.isHidden) {
              return a.isHidden ? 1 : -1;
            }
            return new Date(b.createdOn) - new Date(a.createdOn);
          });
          this.filterLoading = false;
        })
        .catch(() => {
          this.isPollLoaded = true;
          this.isPollError = true;
          this.filterLoading = true;
        });
    },
    togglePresentQuestion(item) {
      ApiClient.submitFreeTextPollAnswerChanges(
        this.eventId,
        this.pollAccessKey,
        item.answerId,
        item.label,
        item.isSelected ? 1 : 0
      )
        .then(() => {
          this.fetchItems();
        })
        .catch(() => {
          this.isPollLoaded = true;
          this.isPollError = true;
        });
    },
    toggleListQuestion(item) {
      ApiClient.submitFreeTextPollAnswerChanges(
        this.eventId,
        this.pollAccessKey,
        item.answerId,
        item.label,
        item.isHidden ? 3 : 2
      )
        .then(() => {
          this.fetchItems();
        })
        .catch(() => {
          this.isPollLoaded = true;
          this.isPollError = true;
        });
    },
    handleDates(e) {
      this.fromDate = e.from;
      this.toDate = e.to;

      if (this.toDate || (this.fromDate && this.toDate)) {
        this.fetchItems({
          eventId: this.eventId,
          pollAccessKey: this.pollAccessKey,
          fromDate: this.fromDate,
          toDate: this.toDate,
          groups: this.groupsFilter
        });
      }
    },
    handleCheckBoxFilters(e) {
      this.filterLoading = true;
      this.groupsFilter = e;
      this.fetchItems({
        eventId: this.eventId,
        pollAccessKey: this.pollAccessKey,
        fromDate: this.fromDate,
        toDate: this.toDate,
        groups: this.groupsFilter
      });
    },
    checkScreenSize() {
      // Use window.innerWidth to get the current viewport width
      // You can adjust the breakpoint value according to your requirements
      if (window.innerWidth >= 1024) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    },
    refreshItems() {
      this.fetchItems();
    }
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}
.item {
  .time {
    color: #888;
  }
  .button-primary {
    background: #52cdda;
    border-color: #52cdda;
    color: #fff;
  }
  .button-secondary {
    background: #720600;
    border-color: #720600;
    color: #fff;
  }
}

@media screen and (max-width: 1023px) {
  .slider {
    display: none;
    position: fixed;
    top: -100%; /* Initially hide the slider off-screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.5s; /* Add a smooth transition for opening and closing */
    z-index: 999; /* Ensure the slider is above other content */
  }

  .slider.open {
    top: 0; /* Move the slider to the top of the screen when open */
  }
}
.slider {
  display: flex;
}
.poll-results {
  overflow-y: auto;
  height: calc(100vh - 5em);
  .card {
    .toggle-type {
      color: var(--color-secondary-bg);
      opacity: 0.75;
    }
  }
}
.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 5em);
}
</style>
